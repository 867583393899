import React, { Fragment, useReducer, useMemo, useEffect, useState } from 'react';
import NewAdvanceTextInput from "../../../../Ui/Inputs/NewAdvanceTextInput/NewAdvanceTextInput";
import { useDispatch, useSelector } from 'react-redux';
import { patchCompanyItem } from '../../../../../store/actions';
import { useFirstRender } from '../../../../../hooks/useFirstRender';
import FormEditButtonArea from "../../../../Ui/Inputs/FormEditButtonArea/FormEditButtonArea";
import NewAdvanceDateTimePicker from "../../../../Ui/Inputs/NewAdvanceDateTimePicker/NewAdvanceDateTimePicker";
import { Col, OverlayTrigger, Popover, FormGroup, Checkbox, FormControl, ControlLabel, Badge, Button } from 'react-bootstrap';
import moment from 'moment';
import BootstrapRowColCombo from "../../../../Bootstrap/BootstrapRowColCombo/BootstrapRowColCombo";
import NewAdvanceDropdown from "../../../../Ui/Inputs/NewAdvanceDropdown/NewAdvanceDropdown";
import NewAdvanceSliderButton from "../../../../Ui/Inputs/NewAdvanceSliderButton/NewAdvanceSliderButton";
import NewTitleHeader from "../../../../Ui/Headers/NewTitleHeader/NewTitleHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCreditCard, faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import { faExclamationCircle, faExclamationTriangle } from '@fortawesome/pro-light-svg-icons';
import { BsFillQuestionCircleFill } from "react-icons/bs";
import classes from './ProPlanUpgradeForm.module.css';
import {
    isNumber,
    isSuccessOrNull,
    isDollarAmount
} from '../../../../../utils/validation';
import ReactivatePlanModal from '../../../../Modals/ReactivePlanModal/ReactivatePlanModal';
import { postProPlan } from '../../../../../utils/services/turboPlus2RestCalls';
import { showToastError, showToastSuccess } from '../../../../../utils/toastMessages';

const init = company => {
    return {
        inEditMode: false,
        loading: false,
        submitDisabled: true,
        activeRoleLimit: company.active_role_limit ? company.active_role_limit.toString() : '',
        planStartDate: company.plan_start_date ? moment.utc(company.plan_start_date).toDate() : null,
        planRenewalDate: company.customer_pricing_plan && company.customer_pricing_plan.next_billing_date ? new Date(company.customer_pricing_plan.next_billing_date.split('T')[0] + 'T00:00:00') : null,
        planEndDate: company.plan_end_date ? moment.utc(company.plan_end_date).toDate() : null,
        cancelationDate: company.customer_pricing_plan && company.customer_pricing_plan.cancelation_date ? moment.utc(company.customer_pricing_plan.cancelation_date).toDate() : null,
        billingCycle: company.customer_pricing_plan ? company.customer_pricing_plan.subscription_type : 'Annual',
        requiresReactivation: company.customer_pricing_plan && company.customer_pricing_plan.requires_reactivation ? true : false,
        hearAboutUs: company.customer_pricing_plan ? company.customer_pricing_plan.how_did_you_hear_about_us : null,
        card_info: company.card_info,
        typeOfPlan: company.role_type_id ? company.role_type_id : company.role_type.id,
        isFreeTrial: company.customer_pricing_plan && company.customer_pricing_plan.subscription_type === 'Free Trial' ? true : false,
        isFreeTrialActive: company.customer_pricing_plan &&
            company.customer_pricing_plan.subscription_type === 'Free Trial' &&
            company.customer_pricing_plan.free_trial_ends_at &&
            moment(company.customer_pricing_plan.free_trial_ends_at).isSameOrAfter(moment(), 'day'),
        defaultPaymentMethod: company.invoice ? 'inv' : 'cc',
        validation: {
            activeRoleLimitValidState: null,
            validateActiveRoleLimit: false,
            pricePerMonthValidState: null,
            validatePricePerMonth: false
        },
        ...(company.customer_pricing_plan ? { pricePerMonth: company.customer_pricing_plan.amount } : {})
    }
}

function reducer(state, action) {
    switch (action.type) {
        case 'editEnabled':
            return {
                ...state,
                inEditMode: true,
            }
        case 'loading':
            return {
                ...state,
                loading: true
            }
        case 'enableSubmitButton':
            return {
                ...state,
                submitDisabled: false
            }
        case 'disableSubmitButton':
            return {
                ...state,
                submitDisabled: true
            }
        case 'activeRoleLimitChanged':
            const isValid = isNumber(action.payload) && parseInt(action.payload, 10) >= 6;
            return {
                ...state,
                activeRoleLimit: action.payload,
                validation: {
                    ...state.validation,
                    activeRoleLimitValidState: isValid ? 'success' : 'error',
                    validateActiveRoleLimit: true
                }
            }
        case 'planStartDateChanged':
            return {
                ...state,
                planStartDate: action.payload
            }
        case 'planEndDateChanged':
            return {
                ...state,
                planEndDate: action.payload
            }
        case 'forceCollectCardChanged':
            return {
                ...state,
                forceCollectCard: action.payload
            }
        case 'typeOfPlanChanged':
            return {
                ...state,
                typeOfPlan: action.payload
            }
        case 'paymentMethodChanged':
            return {
                ...state,
                defaultPaymentMethod: action.payload
            }
        case 'billingCycleChanged':
            return {
                ...state,
                billingCycle: action.payload
            }
        case 'hearAboutUsChanged':
            return {
                ...state,
                hearAboutUs: action.payload
            }
        case 'pricePerMonthChanged':
            return {
                ...state,
                pricePerMonth: action.payload,

            }
        case 'reset':
            return init(action.payload);
        default:
            throw new Error();
    }
}

const ProPlanUpgradeForm = ({ setProPlanMode }) => {
    const dispatchRedux = useDispatch();
    const companyId = useSelector(state => state.rolesSearchSettings.selectedCompanyId);
    const companies = useSelector(state => state.companies.data);
    const company = useMemo(() => companies.find(x => x.id === companyId), [companies]);
    const [state, dispatch] = useReducer(reducer, company, init);
    const isFirstRender = useFirstRender();
    const typeOfPlans = useSelector(state => state.roles.typeOfPlans)
    const paymentMethodsDropdown = [{ id: 'inv', name: "Invoice" }, { id: 'cc', name: "Credit Card" }]
    const billingCycleDropdown = [{ id: 'Month to Month', name: "Month to Month" }, { id: 'Annual', name: "Annual" }]
    const [formLoading, setFormLoading] = useState(false);
    const hearAboutUsOptions = [
        { id: null, name: "Select one" },
        { id: "Social Media (LinkedIn, Twitter, etc)", name: "Social Media (LinkedIn, Twitter, etc)" },
        { id: "Search Engines (Google, Yahoo, etc)", name: "Search Engines (Google, Yahoo, etc)" },
        { id: "Lever", name: "Lever" },
        { id: "Greenhouse", name: "Greenhouse" },
        { id: "ICIMs", name: "ICIMs" },
        { id: "SAP", name: "SAP" },
        { id: "Word of Mouth", name: "Word of Mouth" },
        { id: "Website", name: "Website" },
        { id: "Other", name: "Other" },
    ];

    const subscriptionTypePopOver = (
        <Popover id="subscription-type-popover">
            The 'Subscription Type' indicates whether the customer is on a month-to-month or yearly plan.
        </Popover>
    );

    const planRenewalDatePopOver = (
        <Popover id="plan-renewal-date-popover">
            The 'Plan Renewal Date' is the date when a plan is scheduled to renew unless canceled. For e-commerce plans, customers can
            choose between monthly or yearly plans. For manually created companies, we suggest setting the 'Plan Renewal Date' one year
            ahead of the 'Plan Start Date'.
        </Popover>
    );

    const PopoverHandler = ({ overlay }) => (
        <OverlayTrigger
            trigger={["focus", "hover"]}
            placement="top"
            overlay={overlay}
        >
            <BsFillQuestionCircleFill className={classes.HelpIcon} />
        </OverlayTrigger>
    );

    useEffect(() => {
        if (!isFirstRender) {
            dispatch({ type: 'reset', payload: company });
        }
    }, [company]);

    const handleEditEnabled = () => {
        dispatch({ type: 'editEnabled' });
    }

    const handleEditCanceled = () => {
        dispatch({ type: 'reset', payload: company });
    }

    useEffect(() => {
        const { activeRoleLimitValidState } = state.validation;
        if (isSuccessOrNull(activeRoleLimitValidState)) {
            dispatch({ type: 'enableSubmitButton' });
        } else {
            dispatch({ type: 'disableSubmitButton' });
        }
    }, [state.validation]);

    const handleCreateProPlan = () => {
        setFormLoading(true);
        postProPlan({
            active_role_limit: parseInt(state.activeRoleLimit, 10),
            billing_cycle: state.billingCycle,
            plan_start_date: state.planStartDate,
            plan_end_date: state.planEndDate,
            role_type_id: parseInt(state.typeOfPlan, 10),
            payment_method: state.defaultPaymentMethod,
            price_per_month: parseFloat(state.pricePerMonth),
            customer_id: company.id
        }).then(res => {
            setProPlanMode(false);
            showToastSuccess('Pro plan created successfully');
        }).catch(err => {
            showToastError(err.message ?? 'Something went wrong');
        }).finally(() => {
            setFormLoading(false);
        });
    }

    const handleActiveRoleLimitChanged = (e) => {
        const value = e.target.value;
        if (/^\d*$/.test(value)) {
            dispatch({ type: 'activeRoleLimitChanged', payload: value });
        }
    }

    const handleBillingCycleChanged = (e) => {
        dispatch({ type: 'billingCycleChanged', payload: e.currentTarget.value });
    }

    const handleHearAboutUsChanged = (e) => {
        dispatch({ type: 'hearAboutUsChanged', payload: e.currentTarget.value });
    }

    const handleTypeOfPlanChanged = (e) => {
        dispatch({ type: 'typeOfPlanChanged', payload: e.currentTarget.value });
    }

    const handlePlanStartDateChanged = (value) => {
        dispatch({ type: 'planStartDateChanged', payload: value });
    }

    const handlePlanEndDateChanged = (value) => {
        dispatch({ type: 'planEndDateChanged', payload: value });
    }

    const handlePaymentMethodChanged = (e) => {
        dispatch({ type: 'paymentMethodChanged', payload: e.currentTarget.value });
    }

    const handleForceCollectCardChanged = (value) => {
        dispatch({ type: 'forceCollectCardChanged', payload: value });
    }

    const handlePricePerMonthChanged = (e) => {
        const value = e.target.value;
        const regex = /^\d*\.?\d{0,2}$/;
        if (regex.test(value) || value === "") {
            dispatch({ type: 'pricePerMonthChanged', payload: value });
        }
    }

    const isReactivating = state.inEditMode && company.is_from_drive_through && state.cancelationDate;
    const [showReactivatePlanModal, setShowReactivatePlanModal] = useState(false);

    const handleSaveButtonBehavior = () => {
        if (isReactivating) {
            setShowReactivatePlanModal(true);
        } else {
            handleSubmitChanges();
        }
    }

    const planRenewalDate = () => {
        if (isReactivating) {
            return null;
        }
        if (state.billingCycle === 'Month to Month') {
            return state.planRenewalDate;
        }
        return state.planEndDate;
    }

    const isFormValid = () => {
        return state.activeRoleLimit >= 6 && state.typeOfPlan && state.billingCycle && state.planStartDate && state.planEndDate && state.pricePerMonth;
    }


    return (
        <>
            <Fragment>
                <BootstrapRowColCombo>
                    <Col lg={6}>
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                            <div style={{ marginTop: '12px' }}>
                                <NewTitleHeader title="Type of plan" />
                            </div>
                        </div>
                    </Col>
                </BootstrapRowColCombo>
                <BootstrapRowColCombo>

                    <Col xs={6}>
                        <NewAdvanceTextInput
                            loading={state.loading}
                            onChange={handleActiveRoleLimitChanged}
                            value={state.activeRoleLimit}
                            validationState={state.validation.activeRoleLimitValidState}
                            helpText={state.validation.activeRoleLimitValidState === 'error' ? 'Error: Minimum number of roles is 6 on a Pro plan' : null}
                            placeholder="6+"
                            label="Active Role Limit" />
                    </Col>
                    <Col xs={6}>
                        <NewAdvanceDropdown
                            options={typeOfPlans}
                            value={state.typeOfPlan}
                            onChange={handleTypeOfPlanChanged}
                            label="Default Role Type" />
                    </Col>
                </BootstrapRowColCombo>

                <BootstrapRowColCombo>
                    <Col lg={6}>
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                            <div style={{ marginTop: '40px' }}>
                                <NewTitleHeader title="Subscription" />
                            </div>
                        </div>
                    </Col>
                </BootstrapRowColCombo>
                <BootstrapRowColCombo>
                    <Col xs={6}>
                        <NewAdvanceDropdown
                            options={billingCycleDropdown}
                            value={state.billingCycle}
                            onChange={handleBillingCycleChanged}
                            label={
                                <div className={classes.LabelWithPopover}>
                                    <span style={{ fontSize: 16 }}>Subscription type</span>
                                    <PopoverHandler overlay={subscriptionTypePopOver} />
                                </div>
                            }
                        />
                    </Col>

                </BootstrapRowColCombo>
                {state.billingCycle === 'Annual' && (
                    <BootstrapRowColCombo style={{ marginBottom: '10px' }}>
                        <Col xs={12} className={classes.WarningInfoBox}>

                            <div style={{ display: 'flex', height: '100%', paddingRight: '10px' }}>
                                <FontAwesomeIcon icon={faExclamationCircle} color="#F6C333" className={classes.IconExclamationCircle} />
                            </div>
                            <span><strong>Warning:</strong> By selecting a year-to-year plan, the customer commits to a full year of service. If they cancel mid-term, they will continue to be charged monthly until the end of the 12-month period, with no option to downgrade the plan during this time. This change will take effect on the upcoming renewal date.</span>

                        </Col>
                    </BootstrapRowColCombo>
                )}
                <BootstrapRowColCombo>
                    <Col xs={6}>
                        <NewAdvanceDateTimePicker
                            disabled={company.is_from_drive_through || !state.inEditMode}
                            label="Subscription start date"
                            value={isReactivating ? null : state.planStartDate}
                            onChange={handlePlanStartDateChanged}
                            placeholderText={isReactivating ? 'Will be set automatically' : null}
                        />
                    </Col>
                    <Col xs={6}>

                        <NewAdvanceDateTimePicker
                            disabled={!state.inEditMode}
                            value={state.planEndDate}
                            onChange={handlePlanEndDateChanged}
                            label="Subscription renewal date"
                        />
                    </Col>
                </BootstrapRowColCombo>
                <BootstrapRowColCombo>
                    <Col lg={12}>
                        <p><strong>Important:</strong> Once a plan is updated, the new price will be set on Shopify and charged on the next billing date, along with a surcharge to cover the days prior to the renewal date. The customer will automatically receive an email with the updated price and surcharge details.</p>
                    </Col>
                </BootstrapRowColCombo>
                <BootstrapRowColCombo>
                    <Col lg={6}>
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                            <div style={{ marginTop: '40px' }}>
                                <NewTitleHeader title="Payment" />
                            </div>
                        </div>
                    </Col>
                </BootstrapRowColCombo>

                {company.customer_pricing_plan && !state.isFreeTrial && <BootstrapRowColCombo>
                    <Col xs={6}>
                        <NewAdvanceDropdown
                            label="Payment Method"
                            options={paymentMethodsDropdown}
                            value={state.defaultPaymentMethod}
                            onChange={handlePaymentMethodChanged}
                            placeholder="-- SELECT --" />
                    </Col>

                    <Col xs={6}>
                        <NewAdvanceTextInput
                            loading={state.loading}
                            onChange={handlePricePerMonthChanged}
                            value={state.pricePerMonth}
                            type="number"
                            step="0.01"
                            label="Price per month (USD)" />
                    </Col>
                </BootstrapRowColCombo>}
                <BootstrapRowColCombo>
                    <Col xs={12} className={classes.WarningInfoBox}>

                        <div style={{ display: 'flex', height: '100%', paddingRight: '10px' }}>
                            <FontAwesomeIcon icon={faExclamationCircle} color="#F6C333" className={classes.IconExclamationCircle} />
                        </div>
                        {state.defaultPaymentMethod === 'cc' ? (
                            <span><strong>Note:</strong> If credit card is selected, the plan will update on Shopify, and the new price will be charged on the next billing date. If the customer is upgraded to a Pro plan mid-month, a surcharge will apply, which will be calculated based on the price difference and remaining days before the Subscription renewal date, and charged on the upcoming billing date.</span>
                        ) : (
                            <span><strong>Note:</strong> These changes will be reflected on the customer's platform as soon as they are saved. The plan on Shopify will be cancelled, and you will be responsible for charging any surcharges based on the price difference and remaining days before the subscription renewal, along with all upcoming billing charges.</span>
                        )}

                    </Col>
                </BootstrapRowColCombo>



                {(state.cancelationDate && !state.requiresReactivation) && (
                    <BootstrapRowColCombo>
                        <Col xs={12} className={classes.CancellationInfoBox} style={{ alignItems: 'unset !important' }}>
                            <FontAwesomeIcon icon={faExclamationTriangle} color="red" className={classes.IconExclamationTriangle} />
                            <span> This customer canceled their subscription on {moment.utc(company.customer_pricing_plan.cancelation_date + 'T12:00:00Z').local().format('MMMM D, YYYY')}. </span>
                            <strong>Plan active until {state.planEndDate ? moment.utc(company.plan_end_date).local().format('MMMM D, YYYY') : 'N/A'}.</strong>
                        </Col>
                    </BootstrapRowColCombo>
                )}

                <BootstrapRowColCombo>
                    <Col xs={12} style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '40px' }}>
                        <Button
                            onClick={handleCreateProPlan}
                            bsStyle="info"
                            style={{ padding: '8px 40px' }}
                            disabled={!isFormValid() || formLoading}
                            loading={formLoading}
                        >
                            {formLoading ? 'Creating...' : 'Create Pro Plan'}
                        </Button>
                    </Col>
                </BootstrapRowColCombo>

                <br></br><br></br>
            </Fragment>
        </>
    );
};

export default React.memo(ProPlanUpgradeForm);