import React, {Fragment, useState} from 'react';
import {Col} from 'react-bootstrap';
import FormPanel from "../FormPanel/FormPanel";
import CompanyInfoForm from "./Components/CompanyInfoForm/CompanyInfoForm";
import CompanyUsersForm from "./Components/CompanyUsersForm/CompanyUsersForm";
import CompanyPlanForm from './Components/CompanyPlanForm/CompanyPlanForm';
import ProPlanUpgradeForm from './Components/ProPlanUpgradeForm/ProPlanUpgradeForm';
import RolesHeader from "../RolesHeader/RolesHeader";
import BootstrapRowColCombo from "../../Bootstrap/BootstrapRowColCombo/BootstrapRowColCombo";
import TabsSection from "../TabsSection/TabsSection";
import TabItem from "../TabsSection/Components/TabItem/TabItem";
import { useSelector } from 'react-redux';

const AccountSection = () => {
    const [currentTab, setCurrentTab] = useState('info');
    const [proPlanMode, setProPlanMode] = useState(false);

    const companyId = useSelector(state => state.rolesSearchSettings.selectedCompanyId);
    const companies = useSelector(state => state.companies.data);
    const company = companies.find(x => x.id === companyId);

    const handleTabSelected = (key) => {
        setCurrentTab(key);
    }

    return (
        <Fragment>
            {!proPlanMode ? (<><BootstrapRowColCombo>
                <Col xs={12}>
                    <RolesHeader />
                </Col>
            </BootstrapRowColCombo>
            <TabsSection activeKey={currentTab} handleTabSelected={handleTabSelected} unmountOnExit={true}>
                <TabItem eventKey="info" title="Company Info">
                    <FormPanel title="Company Basics">
                        <CompanyInfoForm />
                    </FormPanel>
                </TabItem>
                <TabItem eventKey="plan" title="Company Plan">
                    <FormPanel title="Company Plan">
                        <CompanyPlanForm setProPlanMode={setProPlanMode} />
                    </FormPanel>
                </TabItem>
                <TabItem eventKey="users" title="Users">
                    <CompanyUsersForm />
                </TabItem>
            </TabsSection></>) : (<>
            <ProPlanHeader companyName={company.name} />
            <TabsSection activeKey={'general'}>
                <TabItem eventKey="general" title="">
                    <FormPanel title="Customer Pro Plan">
                        <ProPlanUpgradeForm setProPlanMode={setProPlanMode} />
                    </FormPanel>
                </TabItem>
            </TabsSection>
            </>)}
        </Fragment>
    );
};

const ProPlanHeader = ({ companyName }) => {
    return (
        <div style={{ textAlign: 'center', marginTop: '40px', padding: '0 40px' }}>
            <p style={{ fontSize: '28px', fontWeight: 'bold', color: '#1A234B' }}>Upgrade {companyName} to a Pro Plan</p>
            <p style={{ fontSize: '16px', color: '#838485' }}>You are upgrading <span style={{ fontWeight: 'bold' }}>{companyName}</span> to a Pro plan. Pro plans start at 6 roles or more. For fewer than 6 roles, the customer must select them directly through their Rolebot platform. </p>
        </div>
    );
}

export default AccountSection;