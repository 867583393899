import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import NewAdvanceDropdown from "../../Ui/Inputs/NewAdvanceDropdown/NewAdvanceDropdown";
import { useEffect, useState } from "react";
import { reactivatePricingPlan } from "../../../utils/services/turboPlus2RestCalls";
import { showToastError, showToastSuccess } from "../../../utils/toastMessages";
import { postCompanyReactivationRequest } from "../../../store/actions/companies";
import { PATCHED_COMPANY_RETURNED } from "../../../store/actions/actionsTypes";

const ReactivatePlanModal = ({ show, onHide, customer, state }) => {
    const typeOfPlans = useSelector(appState => appState.roles.typeOfPlans)
    const [paymentMethod, setPaymentMethod] = useState('')
    const dispatch = useDispatch()

    const handlePaymentMethodChanged = (value) => {
        setPaymentMethod(value.target.value)
    }
    const handleSaveChanges = () => {
        const payload = {
            active_role_limit: state.activeRoleLimit === "" ? null : state.activeRoleLimit,
            plan_start_date: state.planStartDate === "" ? null : state.planStartDate,
            plan_end_date: state.planEndDate === "" ? null : state.planEndDate,
            role_type_id: parseInt(state.typeOfPlan, 10),
            price_per_month: state.pricePerMonth === "" ? null : state.pricePerMonth,
            subscription_type: state.billingCycle ?? null,
            payment_method: paymentMethod
        };

        reactivatePricingPlan(customer.id, payload)
            .then(({ customer: company }) => {
                dispatch({
                    type: PATCHED_COMPANY_RETURNED, payload: {
                        ...company, card_info: null,
                        contactOutCreditsUsed: null,
                        rocketReachCreditsUsed: null
                    }
                });
                showToastSuccess('Plan reactivated successfully')
                onHide()
            })
            .catch(e => showToastError(e.message));
    }

    return (
        <Modal show={show} onHide={onHide} centered={true}>
            <Modal.Header closeButton style={{ borderBottom: 'none' }}>
                <Modal.Title>Confirmation required</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>You’re reactivating {customer.name}'s e-commerce plan.</p>
                <p>In order to avoid errors or unintended charges, please verify the information below before proceeding:</p>
                <ul style={{ marginBottom: '20px' }}>
                    <li>Active role limit: <strong>{state.activeRoleLimit}</strong></li>
                    <li>Default role type: <strong>{typeOfPlans?.find(plan => plan.id === parseInt(state.typeOfPlan, 10))?.name ?? ''}</strong></li>
                    <li>Subscription type: <strong>{state.billingCycle}</strong></li>
                    <li>Price per month (USD): <strong>${state.pricePerMonth}</strong></li>
                </ul>

                <div style={{ padding: '0px 20px' }}>
                    <div style={{ width: '60%' }}>
                        <NewAdvanceDropdown
                            label="Select payment method"
                            options={[{ id: 'inv', name: "Invoice" }, { id: 'cc', name: "Credit Card" }]}
                            value={paymentMethod}
                            onChange={handlePaymentMethodChanged}
                            placeholder="Select..." />
                    </div>

                    {paymentMethod === 'inv' && (
                        <div style={{ color: '#838485', fontStyle: 'italic' }}>
                            <p>Please note: The customer will be able to use the platform as soon as these changes are saved. The 'Plan start date' will be set to today and the 'Plan renewal date' will be automatically scheduled based on the start date and the chosen subscription type.</p>

                        </div>
                    )}

                    {paymentMethod === 'cc' && (
                        <div style={{ color: '#838485', fontStyle: 'italic' }}>
                            <p>Please note: Reactivating this customer's e-commerce plan will prompt Rolebot to request the company's admins to add a new credit card for payment. The plan will commence as soon as payment is completed.</p>
                            <p>The 'Plan start date' will reflect the payment completion date. The 'Plan renewal date' will be automatically scheduled based on this start date and the chosen subscription type.</p>
                        </div>
                    )}
                </div>



                <p style={{ marginTop: '30px' }}>Are you sure you'd like to save these changes?</p>
            </Modal.Body>
            <Modal.Footer style={{ borderTop: 'none' }}>
                <Button variant="secondary" onClick={onHide}>Cancel</Button>
                <Button onClick={handleSaveChanges}
                    disabled={!paymentMethod}
                    variant="primary"
                    style={{ background: 'linear-gradient(180deg, #80BC73 0%, #59954C 100%)', color: 'white', textShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)' }}>Yes, save changes</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ReactivatePlanModal;

background: ;
